import React from 'react'
import { useEffect } from 'react'
type Props = {}
const Induction = (props: Props) => {
    useEffect(()=>{
        window.location.replace("https://docs.google.com/forms/d/e/1FAIpQLSdIjwFvohd0KZSRi1puBMIMnGHKHktPoA3kMCoVIS0LaRWZfw/viewform")
    },[])
  return (
    <div></div>
  )
}

export default Induction