import React from 'react'
import { useEffect } from 'react'
type Props = {}
const Registeration = (props: Props) => {
    useEffect(()=>{
        window.location.replace("https://konfhub.com/netnexsrm")
    },[])
  return (
    <div></div>
  )
}

export default Registeration